<template>
<div class="content-wrapper">
  <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

  <div class="content">

    <div class="card">
      <validation-observer ref="VFormMESO">
        <b-form @submit.prevent="doSubmit()">
          <div class="bg-white card-header">
            <h6 class="card-title font-weight-semibold">Form Pelaporan Efek Samping Obat</h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="form-group col-md-4">
                <label>Kode Sumber Data</label>
                <b-form-input placeholder="" v-model="row.kode_sumber_data" />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-2">
                <label>Berat Badan</label>
                <div class="input-group">
                  <b-form-input placeholder="" :formatter="Formatter.decimalNumber" v-model="row.berat_badan" />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">kg</span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-2">
                <label>Pasien Hamil <small class="txt_mandatory">*</small></label>
                <v-select
                  v-model="row.pasien_hamil"
                  :options="Config.mr.yesNoOptV2"
                  :reduce="v => v.value"
                  label="text"
                />

                <VValidate 
                  name="Pasien Hamil"
                  v-model="row.pasien_hamil"
                  :rules="mrValidation.pasien_hamil"
                />
              </div>
              <div class="form-group col-lg-2">
                <label>Suku</label>
                <v-select
                  v-model="row.suku"
                  :options="$parent.mRoSuku"
                  :reduce="v => v.value"
                  label="text"
                  :cleareable="true"
                />
              </div>
              <div class="form-group col-lg-3">
                <label>Pekerjaan</label>
                <v-select
                  v-model="row.pekerjaan"
                  :options="$parent.mPekerjaan"
                  :reduce="v => v.value"
                  label="text"
                  :cleareable="true"
                />
              </div>

              <div class="form-group col-md-3" v-if="row.pekerjaan == 99999">
                <label>Pekerjaan Lainnya <small class="text-muted"></small> <small
                class="txt_mandatory">*</small></label>
                <b-form-input v-model="row.pekerjaan_text" :formatter="Formatter.normalText" placeholder="Pekerjaan Lainnya" />
                <VValidate 
                  name="Pekerjaan Lainnya" 
                  v-model="row.pekerjaan_text" 
                  :rules="{required: 1}"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="wrap_line">
                  <div class="form-group mb-0">
                    <label class="" for="">Penyakit Utama <span class="text-danger">*</span></label>
                    <b-form-textarea rows="2" placeholder="Jenis Pertanyaan" v-model="row.penyakit_utama"></b-form-textarea>

                    <VValidate 
                      name="Penyakit Utama"
                      v-model="row.penyakit_utama"
                      :rules="mrValidation.penyakit_utama"
                    />
                  </div>
                  <div class="form-group mb-0">
                    <label class="" for="">Kesudahan Penyakit Utama <span class="text-danger">*</span></label>

                    <div class="row">
                      <div class="col-md-6 mb-2" v-for="(v, k) in mrKesudahanPenyakitUtama" :key="`kpu-${k}`">
                        <b-form-checkbox
                          v-model="row.kesudahan_penyakit_utama"
                          v-for="(dt, kdt) in v"
                          :key="`dtkpu-${kdt}`"
                          :value="{ ...dt  }"
                        >
                          {{ dt.label }}

                          <div class="input-group mt-2" v-if="dt.has_note && checkDataRow(dt.value, 'kesudahan_penyakit_utama')">
                            <b-form-input 
                              class="form-control"
                              @change="updateValueCheckbox($event, dt.value, 'kesudahan_penyakit_utama', row.kesudahan_penyakit_utama)" 
                              :value="dt.note"
                            />
                          </div>
                        </b-form-checkbox>
                      </div>
                    </div>

                    <VValidate 
                      name="Kesudahan Penyakit Utama"
                      :value="(row.kesudahan_penyakit_utama||[]).length > 0 ? true : null"
                      :rules="mrValidation.kesudahan_penyakit_utama"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="wrap_line">
                  <div class="form-group mb-0">
                    <label class="" for="">Penyakit/Kondisi yang menyertai</label>
                    <div class="row">
                      <div class="col-md-6 mb-2" v-for="(v, k) in mrPenyakitMenyertai" :key="`kpu-${k}`">
                        <b-form-checkbox
                          v-model="row.penyakit_yang_menyertai"
                          v-for="(dt, kdt) in v"
                          :key="`dtkym-${kdt}`"
                          :value="dt.has_note ? { ...dt, note: getValueCheckbox(dt.value, 'penyakit_yang_menyertai', 'note')||'' } : { ...dt  }"
                        >
                          {{ dt.label }}

                          <div class="input-group mt-2" v-if="dt.has_note && checkDataRow(dt.value, 'penyakit_yang_menyertai')">
                            <b-form-input 
                              class="form-control"
                              @change="updateValueCheckbox($event, dt.value, 'penyakit_yang_menyertai', row.penyakit_yang_menyertai)" 
                              :value="dt.note"
                            />
                          </div>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="" for="">Bentuk/manifestasi E.S.O. yang terjadi <span class="text-danger">*</span></label>
                  <b-form-textarea rows="2" class="form-control" placeholder="Bentuk/manifestasi E.S.O. yang terjadi" v-model="row.manifestasi_eso"></b-form-textarea>

                  <VValidate 
                    name="Bentuk/manifestasi E.S.O."
                    v-model="row.manifestasi_eso"
                    :rules="mrValidation.manifestasi_eso"
                  />
                </div>
                <div class="form-group">
                  <label class="" for="">Tanggal Mula Terjadi <span class="text-danger">*</span> </label>
                  <div class="input-group">
                    <datepicker
                      input-class="form-control transparent"
                      placeholder="Tanggal Mula terjadi" class="my-datepicker"
                      calendar-class="my-datepicker_calendar"
                      v-model="row.tanggal_mula"
                    >
                    </datepicker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i
                          class="icon-calendar"></i></span>
                    </div>
                  </div>

                  <VValidate 
                    name="Tanggal Mula Terjadi"
                    v-model="row.tanggal_mula"
                    :rules="mrValidation.tanggal_mula"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="" for="">Kesudahan E.S.O</label>
                  <b-form-radio-group
                    v-model="row.kesudahan_eso"
                    :options="$parent.mrKesudahanESO"
                    text-field="label"
                    value-field="label"
                    inline
                    id="kesudahan_eso"
                  ></b-form-radio-group>

                  <VValidate 
                    name="Kesudahan E.S.O,"
                    v-model="row.kesudahan_eso"
                    :rules="mrValidation.kesudahan_eso"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label class="" for="">Riwayat E.S.O yang pernah dialami</label>
                  <b-form-textarea rows="2" class="form-control" placeholder="Riwayat E.S.O yang pernah dialami" v-model="row.riwayat_eso"></b-form-textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 mb-2">
                    <div class="table-responsive">
                      <table class="table table-striped table-hover table-bordered">
                        <tbody>
                          <tr v-for="(v,k) in resepDokter" :key="'resepDokter'+k">
                            <td>
                              <div class="d-flex align-items-center mb-2">
                                <h6 class="mb-0 mr-2 font-weight-semibold">Item Resep {{ k+=1 }}</h6>
                                <a href="javascript:;" @click="removeObat(v,k)" class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i class="icon-trash"></i></a>
                              </div>
                              <div class="row g-2">
                                <div class="col-md-3">
                                  <div>
                                    <label for="">Jenis <strong class="text-danger">*</strong></label>
                                    <v-select placeholder="Pilih Item" v-model="v.aranmesr_jenis" :options="$parent.Config.mr.configResepRanap" label="text" @input="changeJenis($event,k)" :reduce="v=>v.value">
                                    </v-select>
                                    <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi" v-model="v.aranmesr_jenis" :rules="{required : 1}" />
                                  </div>
                                </div>
                                <div class="col-md-5 col-xl-4">
                                  <div>
                                    <label for="">Nama</label>
                                    <div class="d-flex">
                                      <template v-if="v['aranmesr_jenis'] == 1">
                                        <div class="input-group">
                                          <v-select style="flex: 1;" class="med-selection select-paging" placeholder="Pilih Item" :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'" @open="countLen" @search="filterObat($event,k);countLen();" v-model="v.aranmesr_item_id" :options="mObatPreferensi" label="text" @input="selectJumlahSatuan($event,k);autoSaveResepObat(v);" :clearable="true" :reduce="v=>v.value" :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                                            <template slot="selected-option">
                                              <span :class="isAlergiLabel(v.aranmesr_nama)?'text-warning':''">{{ v.aranmesr_nama }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                              <span :class="isAlergiLabel(option.text)?'text-warning':''" v-if="option.preferensi"><b>{{ option.text }}</b></span>
                                              <span :class="isAlergiLabel(option.text)?'text-warning':''" v-else>{{ option.text }}</span>
                                            </template>
                                            <template #list-footer>
                                              <li class="list-paging">
                                                <div class="d-flex align-items-center justify-content-between">
                                                  <b-button @click="changePageObat(v.currentPage||1,k,'min','resepDokter')" size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                                    <i class="icon-arrow-left22 text-white"></i></b-button>
                                                  <small>Halaman {{ v.currentPage||1 }} dari {{ ceilData(v.totalRows/10) }}</small>
                                                  <b-button @click="changePageObat(v.currentPage||1,k,'plus','resepDokter')" size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                                    <i class="icon-arrow-right22 text-white"></i></b-button>
                                                </div>
                                              </li>
                                            </template>
                                          </v-select>
                                          <div class="input-group-append">
                                            <span class="input-group-text"><span>{{v['aranmesr_satuan']}}</span></span>
                                          </div>
                                        </div>
                                        <br />
                                      </template>

                                      <template v-else-if="v['aranmesr_jenis'] == 2">
                                        <div class="input-group">
                                          <v-select style="flex: 1;" class="med-selection select-paging" placeholder="Pilih Item" v-model="v.aranmesr_item_id" :options="mAlatKesehatan" label="text" @input="selectJumlahSatuanAlkes($event,k); autoSaveResepObat(v)" :clearable="true" :reduce="v=>v.value">
                                            <template slot="selected-option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                          </v-select>
                                          <div class="input-group-append">
                                            <span class="input-group-text"><span>{{v['aranmesr_satuan']}}</span></span>
                                          </div>
                                        </div>
                                      </template>

                                      <template v-else-if="v['aranmesr_jenis'] == 3">
                                        <div class="input-group">
                                          <v-select style="flex: 1;" class="med-selection select-paging" placeholder="Pilih Item" v-model="v.aranmesr_item_id" :options="mRacikan" label="text" @input="selectJumlahSatuanRacikan($event,k); autoSaveResepObat(v)" :clearable="true" :reduce="v=>v.value">
                                            <template slot="selected-option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                          </v-select>
                                          <div class="input-group-append">
                                            <span class="input-group-text"><span>{{v['aranmesr_satuan']}}</span></span>
                                          </div>
                                        </div>
                                      </template>

                                      <template v-else-if="v['aranmesr_jenis'] == 5">
                                        <div class="input-group">
                                          <v-select style="flex: 1;" class="med-selection select-paging" placeholder="Pilih Item" v-model="v.aranmesr_item_id" :options="mAlatInfus" label="text" @input="selectJumlahSatuanInfus($event,k); autoSaveResepObat(v)" :clearable="true" :reduce="v=>v.value">
                                            <template slot="selected-option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                              <span>{{ option.text }}</span>
                                            </template>
                                          </v-select>
                                          <div class="input-group-append">
                                            <span class="input-group-text"><span>{{v['aranmesr_satuan']}}</span></span>
                                          </div>
                                        </div>
                                      </template>

                                      <a href="javascript:;" v-if="v['aranmesr_jenis'] !== 3" @click="v['aranmesr_item_id'] = 99999; v['aranmesr_nama'] = 'Lainnya'" v-b-tooltip.hover="'Pilih Lainnya'" class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i class="icon-plus-circle2"></i></a>
                                    </div>
                                    <VValidate :name="'Nama Item '+(k+1)" message="Nama Item Harus Diisi" v-model="v['aranmesr_item_id']" :rules="{required : 1}" />

                                    <div v-if="v['aranmesr_item_id'] == 99999">
                                      <b-form-input type="text" v-model="v['aranmesr_item_lainnya']" class="form-control" id="itemCount" />
                                      <VValidate :name="'Item Lainnya '+(k+1)" message="Nama Item Lainnya Harus Diisi" v-model="v['aranmesr_item_lainnya']" :rules="{required : 1}" />
                                    </div>

                                    <a v-if="v.aranmesr_jenis == 3 && v['aranmesr_item_id']" href="javascript:;" @click="toRacikan(v)" data-popup="tooltip" title="" data-toggle="modal" data-target="#modalRacikanDetail" class="font-weight-semibold border-bottom" data-original-title="Lihat Detail Resep">Lihat Detail Racikan</a>

                                    <small class="text-warning" v-if="isAlergiLabel(v.aranmesr_nama) ">Obat ini termasuk dalam kategori alergi</small>
                                  </div>
                                </div>
                                <div class="col-md-4 col-xl-2">
                                  <div>
                                    <label for="">No. Bets<strong class="text-danger">*</strong></label>
                                    <b-form-input type="text" v-model="v['aranmesr_no_bets']" @change="autoSaveResepObat(v)" />
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div>
                                    <label for="">Cara<strong class="text-danger">*</strong></label>
                                    <b-form-textarea v-model="v['aranmesr_cara']" placeholder="Cara" :formatter="Formatter.normalText" @change="autoSaveResepObat(v)" />
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <label for="">Frekuensi<strong class="text-danger">*</strong></label>
                                    <span class="d-flex">
                                      <v-select style="flex: 1;" class="med-selection" placeholder="Pilih Frekuensi" v-model="v.aranmesr_frekuensi" :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value" @input="autoSaveResepObat(v)">
                                      </v-select>
                                      <a href="javascript:;" @click="v['aranmesr_frekuensi'] = 99999" v-b-tooltip.hover="'Pilih Lainnya'" class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i class="icon-plus-circle2"></i></a>
                                    </span>
                                    <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi" v-model="v['aranmesr_frekuensi']" v-if="v.aranmesr_jenis != 2" :rules="{required : 1}" />

                                    <div v-if="v['aranmesr_frekuensi'] == 99999">
                                      <b-form-input type="text" v-model="v['aranmesr_frekuensi_lainnya']" class="form-control" id="itemCount" @change="autoSaveResepObat(v)" />
                                      <VValidate :name="'Frekuensi Lainnya '+(k+1)" message="Frekuensi Lainnya Harus Diisi" v-if="v.aranmesr_jenis != 2" v-model="v['aranmesr_frekuensi_lainnya']" :rules="{required : 1}" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label for="">Tanggal Mulai<strong class="text-danger">*</strong></label>
                                    <div class="input-group">
                                      <datepicker input-class="form-control transparent"
                                        placeholder="Pilih Tanggal" class="my-datepicker" @change="autoSaveResepObat(v)"
                                        calendar-class="my-datepicker_calendar" v-model="v['aranmesr_start_date']">
                                      </datepicker>
                                      <div class="input-group-append calendar-group">
                                      <span class="input-group-text" id="basic-addon2"><i
                                          class="icon-calendar"></i></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <label for="">Tanggal Akhir<strong class="text-danger">*</strong></label>
                                    <div class="input-group">
                                      <datepicker input-class="form-control transparent"
                                        placeholder="Pilih Tanggal" class="my-datepicker" @change="autoSaveResepObat(v)"
                                        calendar-class="my-datepicker_calendar" v-model="v['aranmesr_end_date']">
                                      </datepicker>
                                      <div class="input-group-append calendar-group">
                                      <span class="input-group-text" id="basic-addon2"><i
                                          class="icon-calendar"></i></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <label for="keterangan">Keterangan</label>
                                    <b-form-textarea v-model="v.aranmesr_keterangan" name="itemNotes" id="itemNotes" rows="2" placeholder="cth. obat dihabiskan" class="form-control" @change="autoSaveResepObat(v)"></b-form-textarea>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td class="text-center" colspan="99">
                              <a href="javascript:;" @click="openObat()" class="btn alpha-blue">
                                <i class="icon-plus2 mr-1 align-middle"></i>
                                <span class="align-middle">Tambah Resep</span>
                              </a>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="" for="">Keterangan Tambahan</label>
                      <b-form-textarea rows="2" class="form-control" placeholder="Keterangan Tambahan" v-model="row.keterangan_tambahan"></b-form-textarea>
                    </div>

                    <div class="wrap_line">
                      <h3><span>Pengirim</span></h3>
                      <table cellpadding="5" class="w-100">
                        <tr>
                          <td width="25%">Nama</td>
                          <td width="5%" class="text-center">:</td>
                          <td width="70%">
                            <b-form-input v-model="row.p_nama" :formatter="Formatter.normalText" />
                          </td>
                        </tr>
                        <tr>
                          <td>Keahlian</td>
                          <td class="text-center">:</td>
                          <td>
                            <b-form-input v-model="row.p_keahlian" :formatter="Formatter.normalText" />
                          </td>
                        </tr>
                        <tr>
                          <td>Alamat</td>
                          <td class="text-center">:</td>
                          <td>
                            <b-form-textarea v-model="row.p_alamat"  />
                          </td>
                        </tr>
                        <tr>
                          <td>No Telepon</td>
                          <td class="text-center">:</td>
                          <td>
                            <b-form-input type="tel" v-model="row.p_telepon" :formatter="Formatter.number" />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="wrap_line">
                      <h3><span>Hasil Laboratorium</span></h3>
                      <div class="row">
                        <div class="col-md-12">
                          <table class="table table-bordered table-striped table-sm patient-table">
                            <thead>
                              <tr>
                                <th>Hasil Pemeriksaan </th>
                                <th>Hasil</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v, k) in row.hasil_lab" :key="`hl-${k}`">
                                <td>{{ v.value }}</td>
                                <td>
                                  <div class="result_tab form-group mb-0">
                                    <a :href="$parent.uploader(v.file)" class="btn_view" v-b-tooltip.hover title="Unduh Dokumen Hasil" v-if="v.file" target="_blank">
                                      <i class="icon-file-download"></i>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr v-if="!(row.hasil_lab||[]).length">
                                <td colspan="3">
                                  <h5>Hasil Laboratorium tidak ada</h5>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="col-md-12 mt-3">
                          <div class="form-group">
                            <label class="" for="">Hasil Laboratorium</label>
                            <b-form-textarea rows="2" class="form-control" placeholder="Hasil Laboratorium" v-model="row.data_laboratorium"></b-form-textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th colspan="5">
                            <h4 class="mb-0 font-weight-bold">
                              ALGORITMA NARANJO
                            </h4>
                          </th>
                        </tr>
                        <tr>
                          <th colspan="2" rowspan="2" width="75%">
                            <p class="text-center mb-0">Pertanyaan</p>
                          </th>
                          <th colspan="3">
                            <p class="text-center mb-0">
                              Skala
                            </p>
                          </th>
                        </tr>

                        <tr>
                          <th>
                            <p class="text-center mb-0">Ya</p>
                          </th>
                          <th>
                            <p class="text-center mb-0">Tidak</p>
                          </th>
                          <th>
                            <p class="text-center mb-0">Tidak Diketahui</p>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(v, k) in row.naranjo_data" :key="`naranjo-${k}`">
                          <td colspan="2">{{ v.label }}</td>

                          <td v-for="(dt, kdt) in v.options" :key="`naranjopt-${k}-${kdt}`">
                            <div class="d-flex justify-content-center">
                              <b-form-radio v-model="v.score" :value="dt.value"></b-form-radio>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-bordered">
                      <tr>
                        <td class="bg-info">
                          <p class="font-weight-bold mb-0">Skala: {{ totalScoreNaranjo }} | {{ categoryNaranjano(totalScoreNaranjo) }}</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex flex-column align-items-end">
              <button class="btn btn-labeled btn-labeled-left btn-primary">
                <b><i class="icon-paperplane"></i></b>
                <span>Kirimkan</span>
              </button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</div>
</template>

<script>
import Formatter from '@/libs/Formatter.js'
import Datepicker from 'vuejs-datepicker'
import Config from '@/libs/Config.js'

// import VueTimepicker from 'vue2-timepicker'
// import 'vue2-timepicker/dist/VueTimepicker.css'

import Gen from '@/libs/Gen.js'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'

import {
  stringSimilarity
} from "string-similarity-js"
import $ from 'jquery'

const _ = global._
export default {
  props: {},
  components: {
    PatientInfo,
    Datepicker,
    // VueTimepicker
  },
  data() {
    return {
      patientData: {},
      loading: {
        patientInfo: false,
      },

      obatLen: 0,
    }
  },
  mounted() {
    this.getPatientInfo()
  },
  methods: {
    isInRange(value, min, max, inclusive = true) {      
      if (inclusive) {
        return value >= min && value <= max
      } else {
        return value > min && value < max
      }
    },
    categoryNaranjano(score){
      if(score >= 9){
        return "Highly Probable"
      } else if(this.isInRange(score, 5, 8)) {
        return "Probable"
      } else if(this.isInRange(score, 1, 4)){
        return "Possible"
      } else {
        return "Doubtful"
      }
    },
    updateRow: _.debounce(function(data){
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: {
            type: "update-meso",
            id_registrasi: this.$route.params.pageSlug,
            ...data
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this.loading, 'patientInfo', false)
        if (!data.id) {
          this.$set(data, 'id', resp.data.id)
        }
      })
    }, 1000),
    updateValueCheckbox(value, keyValue, keyData, data){
      this.$set(this.row, keyData, data.map(dt => {
        if(dt.value === keyValue) return { ...dt, note: value }

        return { ...dt }
      }))
    },
    checkDataRow(value, keyData){
      const indexKeyData = (this.row[keyData]||[]).findIndex(dt => dt.value === value)
      return indexKeyData >= 0
    },

    autoSaveResepObat: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-resep-obat',
        data: data
      }      
      if (!this.row.isEdit) {
        Gen.apiRest(
          "/do/" + this.$parent.modulePage, {
            data: dataPost
          },
          "POST"
        ).then(res => {
          console.log(res)
        })
      }
    }, 1000),
    isAlergiLabel(nama) {
      let data = []
      for (let i = 0; i < (this.row.alergi || []).length; i++) {
        if (this.row.alergi[i]['jenis'] == 'Obat') {
          data.push(this.row.alergi[i]['name'])
        }
      }
      let isWarning = 0
      for (let i = 0; i < (data || []).length; i++) {
        let result = stringSimilarity(nama || "", data[i])
        if (result > 0.3) isWarning += 1
      }
      return isWarning
    },
    countLen() {
      setTimeout(() => {
        this.obatLen = $('.sp-col ul li').length
      }, 100)
    },
    removeObat(v, k) {
      console.log(v,k)
      if (v.aranmesr_id) {
        let data = {
          type: 'remove-resep-dokter',
          id: v.aranmesr_id
        }
        Gen.apiRest(
          "/do/" + this.$parent.modulePage, {
            data: data
          },
          "POST"
        ).then(res => {
          this.resepDokter.splice(k, 1)
        })
      } else {
        this.resepDokter.splice(k, 1)
      }
    },
    changePageObat(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
          page += 1
          this[col][k]['currentPage'] += 1
        }
      }

      let data = {
        type: 'select-paging-obat',
        page: page,
        idDokter: this.row.aranr_dokter_id,
        search: this[col][k]['search']
      }
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.mObatPreferensi = res.data.data
      })
    },

    openObat(v = null) {
      let data = {
        aranmesr_aranr_id: this.row.aranr_id,
        aranmesr_aranmes_id: this.row.id,
        aranmesr_jenis: 1,
        aranmesr_item_id: null,
        aranmesr_nama: null,
        aranmesr_jumlah: null,
        aranmesr_satuan: null,
        aranmesr_frekuensi: null,
        aranmesr_frekuensi_lainnya: null,
        aranmesr_keterangan: null,
        aranmesr_code: null,
        aranmesr_is_alergi: null,
        aranmesr_signa1: null,
        aranmesr_signa2: null,
        id: null,
      }

      data.type = 'add-resep-dokter'
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        // this.apiGetForm()
        this.resepDokter.push({ ...data, ...res.data.row })
      })

    },
    resetObat() {
      this.loadingOverlay = true
      let data = {
        id: this.row.aranmesr_id,
        type: 'get-resep-dokter'
      }
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.resepDokter = resp.data
        this.loadingOverlay = false
      })
    },
    addObat() {
      this.rowRacikan.aranmesr_racikan.push({
        value: null,
        dosis: null,
        satuan_dosis: null,
        jumlah: null,
        satuan_jumlah: null,
      })
    },
    ceilData(value) {
      return Math.ceil(value)
    },
    getObat(val) {
      let index = this.mObat.findIndex(x => x.value == val)
      if (index !== -1) {
        return this.mObat[index]['text']
      } else {
        return null
      }
    },

    getMasterObat(data, k) {
      const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.value
      })
      return _.filter(this.mObat, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },
    selectObat(e, k) {
      let index = this.mObat.findIndex(x => x.value == e)
      if (index !== -1) {
        let data = this.mObat[index]
        this.rowRacikan.aranmesr_racikan[k]['dosis'] = data.dosis
        this.rowRacikan.aranmesr_racikan[k]['satuan_dosis'] = data.satuan_dosis
        this.rowRacikan.aranmesr_racikan[k]['satuan_jumlah'] = data.satuan
      }
    },
    changeJenis(e, k) {
      this.resepDokter[k].aranmesr_item_id = null
      this.resepDokter[k].aranmesr_frekuensi = null
      this.resepDokter[k].aranmesr_frekuensi_lainnya = null
    },
    filterObat: _.debounce(function (e, k) {
      if (e) {
        this.resepDokter[k]['search'] = e
        let id = []
        for (let i = 0; i < (this.resepDokter || []).length; i++) {
          if (this.resepDokter[i]['aranmesr_item_id'] && this.resepDokter[i]['aranmesr_jenis'] == 1) {
            id.push(this.resepDokter[i]['aranmesr_item_id'])
          }
        }
        Gen.apiRest('/do/' + 'RanapMESO', {
          data: {
            type: 'select-obat',
            search: e,
            id: id
          }
        }, 'POST').then(res => {
          this.mObatPreferensi = res.data.data
          this.$set(this.resepDokter[k], 'currentPage', 1)
          this.$set(this.resepDokter[k], 'totalRows', res.data.totalRows)
        })
      }
    }, 10),
    selectJumlahSatuan(e, k) {
      let data = {
        id: e,
        type: 'get-by-obat'
      }
      Gen.apiRest(
        "/do/" + 'RanapMESO', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].aranmesr_satuan = res.data.satuan
        this.resepDokter[k].aranmesr_nama = res.data.nama
        this.resepDokter[k].aranmesr_racikan = null
        this.resepDokter[k].aranmesr_dosis = res.data.dosis
        this.resepDokter[k].aranmesr_satuan_dosis = res.data.satuan_dosis
        this.resepDokter[k].aranmesr_item_lainnya = null

        this.isAlergi(res.data.nama, k)
      })
    },
    selectJumlahSatuanAlkes(e, k) {
      let data = {
        id: e,
        type: 'get-by-alkes'
      }
      Gen.apiRest(
        "/do/" + 'RanapMESO', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].aranmesr_satuan = res.data.satuan
        this.resepDokter[k].aranmesr_nama = res.data.nama
        this.resepDokter[k].aranmesr_racikan = null

        this.resepDokter[k].aranmesr_dosis = null
        this.resepDokter[k].aranmesr_satuan_dosis = null
        this.resepDokter[k].aranmesr_frekuensi = null
        this.resepDokter[k].aranmesr_frekuensi_lainnya = null
        this.resepDokter[k].aranmesr_item_lainnya = null
      })
    },
    selectJumlahSatuanInfus(e, k) {
      let data = {
        id: e,
        type: 'get-by-infus'
      }
      Gen.apiRest(
        "/do/" + 'RanapMESO', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].aranmesr_satuan = res.data.satuan
        this.resepDokter[k].aranmesr_nama = res.data.nama
        this.resepDokter[k].aranmesr_racikan = null

        this.resepDokter[k].aranmesr_dosis = null
        this.resepDokter[k].aranmesr_satuan_dosis = null
        this.resepDokter[k].aranmesr_frekuensi = null
        this.resepDokter[k].aranmesr_frekuensi_lainnya = null
        this.resepDokter[k].aranmesr_item_lainnya = null
      })
    },
    selectJumlahSatuanRacikan(e, k) {
      let data = {
        id: e,
        type: 'get-by-racikan'
      }
      Gen.apiRest(
        "/do/" + 'RanapMESO', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].aranmesr_satuan = res.data.satuan
        this.resepDokter[k].aranmesr_nama = res.data.nama
        this.resepDokter[k].aranmesr_racikan = res.data.data

        this.resepDokter[k].aranmesr_dosis = null
        this.resepDokter[k].aranmesr_satuan_dosis = null
        this.resepDokter[k].aranmesr_frekuensi = null
        this.resepDokter[k].aranmesr_frekuensi_lainnya = null
        this.resepDokter[k].aranmesr_item_lainnya = null
      })
    },
    isAlergi(nama, k) {
      let data = []
      for (let i = 0; i < (this.row.alergi || []).length; i++) {
        if (this.row.alergi[i]['jenis'] == 'Obat') {
          data.push(this.row.alergi[i]['name'])
        }
      }
      let isWarning = 0
      for (let i = 0; i < (data || []).length; i++) {
        let result = stringSimilarity(nama || "", data[i])
        if (result > 0.3) isWarning += 1
      }

      this.resepDokter[k]['aranmesr_is_alergi'] = isWarning ? "Y" : "N"
    },
    getValueCheckbox(keyValue, keyData, keyProperty) {
      const findIndex = this.row[keyData].findIndex(dt => dt.value == keyValue)
      if (this.row[keyData][findIndex]) return this.row[keyData][findIndex][keyProperty]
      return null
    },
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },
    doSubmit() {
      this.$refs['VFormMESO'].validate().then(success => {
        if (!success) return false
        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
              <span style="font-size: 16px">
                Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
              </span>              
            `,
          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            const payload = {
              ...this.row,
              id_registrasi: this.$route.params.pageSlug,
              naranjo_score: this.totalScoreNaranjo
            }
            this.$parent.doSubmitMESO({
              ...payload,
              type: 'submit'
            })
          }
        })
      })
    }
  },
  computed: {
    row() {
      return this.$parent.row
    },
    resepDokter() {
      return this.$parent.resepDokter
    },
    mrValidation() {
      return this.$parent.mrValidation
    },
    Formatter() {
      return Formatter
    },
    Config(){
      return Config
    },

    isQuestionMateri() {
      return (this.row.materi || []).find(dtMateri => dtMateri.value === "ADD_QUESTION")
    },

    mrPenyakitMenyertai(){
      return _.chunk(this.$parent.mrPenyakitMenyertai, 2)
    },

    mrKesudahanPenyakitUtama(){
      return _.chunk(this.$parent.mrKesudahanPenyakitUtama, 2)
    },

    mDosis(){
      return this.$parent.mDosis
    },

    mObatPreferensi(){
      return this.$parent.mObatPreferensi
    },

    mRacikan(){
      return this.$parent.mRacikan
    },

    mAlatKesehatan(){
      return this.$parent.mAlatKesehatan
    },
    
    mAlatInfus(){
      return this.$parent.mAlatInfus
    },

    totalScoreNaranjo(){
      return (this.row.naranjo_data||[]).map(dt => dt.score == 99 ? 0 : (dt.score || 0) ).reduce((accumulator, current) => accumulator + current, 0)
    }
  },
  watch: {
    $route() {
      this.getPatientInfo()
    },
    'row.signature'(v) {
      this.setSignature(v)
    },
    'isQuestionMateri'(v) {
      if (!v) this.$set(this.row, 'question', null)
    },
    totalScoreNaranjo(v){
      setTimeout(() => {
        this.updateRow({ ...this.row, naranjo_score: v })        
      }, 100)
    },

    row: {
      handler(v) {
        if(v.id) this.updateRow(v)
      },
      deep: true
    },
  }
}
</script>
<style scoped>

#kesudahan_eso .custom-control.custom-control-inline.custom-radio {
  margin: .5em;
}

</style>