<template>
<div class="content-wrapper">

  <bo-page-title v-if="isList" />
  <div v-if="isList" class="content pt-0">
    <div class="card">
      <div class="bg-white card-header">
        <div class="row align-items-center">
          <div class="col-md-4 col-lg-5">
            <div class="form-row align-items-center">
              <div class="col-lg-4">
                <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
              </div>
              <div class="col-lg-8">
                <div class="form-group mb-0">
                  <div class="input-group">
                    <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'" :ranges="datePickerConfig.ranges" @update="updateValues">
                      <template v-slot:input="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-header">
        <div class="row">
          <div class="col-md-8 col-lg-9">
            <div class="row gx-1">
              <div class="col-md-auto">
                <div class="form-group mb-0">
                  <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i class="icon-spinner11"></i></button>
                </div>
              </div>
              <div class="col-md">
                <div class="row gx-1">

                  <div class="col-md-6 col-lg-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan" :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-6 col-lg-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Cara Masuk -- " @input="doFill" v-model="filter.cara_masuk" :options="Config.mr.caraMasuk" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
              <input class="form-control" v-model="filter.search" @input="doSearch" placeholder="Ketik Nama/No. RM Pasien" />
              <div class="form-control-feedback">
                <i class="icon-search4 text-indigo"></i>
              </div>

              <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn" @click="doResetData()">Reset</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
          <thead>
            <tr>
              <th>NO.</th>
              <th>NO. KEDATANGAN</th>
              <th>TANGGAL MASUK</th>
              <th>NAMA/NO KTP</th>
              <th>DOKTER DPJP</th>
              <th>NO RM</th>
              <th>RUANGAN</th>
              <th>JENIS RAWAT INAP</th>
              <th>TANGGAL LAHIR</th>
              <th>USIA</th>
              <th>PPJP</th>
              <th>STATUS</th>
              <th>AKSI</th>
            </tr>
          </thead>

          <tbody v-if="(dataList||[]).length">
            <template v-for="(v,k) in (dataList||[])">
              <tr :key="k">
                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                <td>
                  <div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
                </td>
                <td>
                  <div>{{v.aranres_tanggal_periksa | moment("DD MMMM YYYY")}}</div>
                </td>
                <td>
                  <div>
                    <a href="javascript:;" @click="toMonitoring(v.aranr_id)" class="font-weight-semibold border-bottom" v-b-tooltip.hover title="Periksa Pasien"> {{v.ap_fullname||"-"}}</a>
                    <br>
                    {{v.ap_code||"-"}}</div>
                  <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                </td>
                <td>{{v.bu_full_name||"-"}}</td>
                <td>
                  <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                  <span>dari {{v.aranres_cara_masuk == 'RAJAL'?'POLI': v.aranres_cara_masuk == 'BBL' ? 'BBL':'UGD'}}</span>
                  <div class="text-success-700 font-weight-semibold">
                    {{v.no_reg||"-"}}
                  </div>
                </td>
                <td>
                  <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
                  <small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
                </td>
                <td>
                  <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
                </td>
                <td>
                  <div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
                  <span v-else> - </span>
                </td>
                <td>
                  <div class="font-weight-semibold">
                    {{v.ap_usia_with_ket || "-"}}
                  </div>
                </td>
                <td>
                  {{v.nama_ppjp||"-"}}
                </td>
                <td>
                  <template>
                    <div :class="`badge ${v.arans_color} mb-1`">{{v.arans_name||"-"}}</div> <br />

                    <div class="badge badge-danger mb-1">{{ v.arcptlsp_type }}</div>
                  </template>

                </td>
                <td>
                  <router-link :to="{ name: modulePage, params: { pageSlug: v.aranr_id } }" v-if="moduleRole('add')" @click="openReturObat(v)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover.left title="Tambah MESO">
                    <i class="icon-plus2"></i>
                  </router-link>

                  <a href="javascript:;" v-b-tooltip.hover.left title="Lihat Detail" v-if="moduleRole('view') && v.show_detail_button" class="btn btn-icon rounded-round btn-sm alpha-info border-info" @click="openDetail(v)">
                    <i class="icon-file-eye"></i>
                  </a>

                  <a href="javascript:;" v-if="moduleRole('download') && v.show_download_button" :to="{ name: modulePage, params: { pageSlug: v.aranr_id } }" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.left title="Download MESO" @click="openDownloadMESO(v)">
                    <i class="icon-file-text"></i>
                  </a>
                </td>

              </tr>
            </template>
          </tbody>
          <tbody v-if="data.data.length == 0">
            <tr>
              <td colspan="99">
                <div class="text-center">
                  <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!data.data">
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
          </tbody>
        </table>

        <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
          <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-left"></i>
          </a>
          <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-right"></i>
          </a>
        </div>
      </div>

      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
        <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
      </b-card-footer>

    </div>

    <b-modal id="downloadDoc" size="md" title="Download" hide-footer>
      <table class="table">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>File</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v, k) in dataModal.dataPreview" :key="`doc-${k}`">
            <td>{{ v.created_date | moment("LLL") }}</td>
            <td>
              <a href="javascript:;" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" @click="downloadMESO(v)">
                <i class="icon-download"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>

    <b-modal id="detail" size="xl" title="Detail" hide-footer>
      <div class="row">
        <div class="col-md-12 mb-2">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="">Pilih Data: </label>
                <v-select v-model="dataModal.selectedDetail" :options="dataModal.dataCreatedDate" :reduce="v => v.value" label="label" @input="openDetail({ ...dataModal, ...dataModal.dataDetail, id: $event })" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="result_tab">
                <h4>Kode Sumber Data</h4>
                <p>{{ dataModal.dataDetail.kode_sumber_data }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Penderita </h6>
            </div>
            <div class="card-body py-2">
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-bordered table-sm">
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Nama</h4>
                          <p>{{ dataModal.ap_fullname }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Berat Badan</h4>
                          <p>{{ dataModal.dataDetail.berat_badan }}kg</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Suku</h4>
                          <p>{{ dataModal.dataDetail.mrsuk_name }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Pekerjaan</h4>
                          <p>{{ dataModal.dataDetail.mp_name }} {{ dataModal.dataDetail.mp_id == 99999 ? `: ${dataModal.dataDetail.pekerjaan_text}` : '' }}</p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Penyakit </h6>
            </div>
            <div class="card-body py-2">
              <div class="row">
                <table class="table table-bordered table-sm">
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Penyakit Utama</h4>
                        <p>{{ dataModal.dataDetail.penyakit_utama }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Kesudahan Penyakit Utama</h4>
                        <div class="row mt-2">
                          <div class="col-md-6" v-for="(v, k) in lodash.chunk(dataModal.dataDetail.kesudahan_penyakit_utama, 2)" :key="`kpu-${k}`">
                            <div class="req-list">
                              <div class="d-flex mb-2" v-for="(dt, kdt) in v" :key="`dtkpu-${kdt}`">
                                <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                                <span class="d-inline-block align-middle">
                                  {{ dt.label }} {{ dt.note ? `: ${dt.note}` : `` }} <span class="text-capitalize" v-if="dt.suffix">{{ dt.suffix }}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Penyakit / kondisi lain yang menyertai</h4>
                        <div class="row mt-2">
                          <div class="col-md-6" v-for="(v, k) in lodash.chunk(dataModal.dataDetail.penyakit_yang_menyertai, 2)" :key="`kpu-${k}`">
                            <div class="req-list">
                              <div class="d-flex mb-2" v-for="(dt, kdt) in v" :key="`dtkpu-${kdt}`">
                                <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                                <span class="d-inline-block align-middle">
                                  {{ dt.label }} {{ dt.note ? `: ${dt.note}` : `` }} <span class="text-capitalize" v-if="dt.suffix">{{ dt.suffix }}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Resep </h6>
            </div>
            <div class="card-body py-2">
              <div class="row">
                <table class="table table-striped table-sm table-hover table-bordered">
                  <thead>
                    <tr>
                      <th rowspan="2">No</th>
                      <th rowspan="2" width="160">Jenis</th>
                      <th rowspan="2" width="320">Nama</th>
                      <th rowspan="2">Bentuk Sediaan</th>
                      <th rowspan="2">No. Bets</th>
                      <th colspan="4">Pemberian</th>
                      <th rowspan="2">Keterangan</th>
                    </tr>
                    <tr>
                      <th width="210">Cara</th>
                      <th width="210">Frekuensi</th>
                      <th width="210">Tanggal Mulai</th>
                      <th width="210">Tanggal Akhir</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, k) in dataModal.dataDetail.resep" :key="`dmresep-${k}`">
                      <td>{{ k+1 }}</td>
                      <td>{{ objResepOptions[v.aranmesr_jenis] }}</td>
                      <td>
                        {{ v.aranmesr_nama }}
                      </td>
                      <td>{{ v.aranmesr_satuan }}</td>
                      <td>{{ v.aranmesr_no_bets }}</td>
                      <td>{{ v.aranmesr_cara }}</td>
                      <td>{{ v.mdo_name }}</td>
                      <td>{{ v.aranmesr_start_date | moment("LL") }}</td>
                      <td>{{ v.aranmesr_end_date | moment("LL") }}</td>
                      <td>
                        {{ v.aranmesr_keterangan }}
                      </td>
                    </tr>

                    <tr v-if="!(dataModal.dataDetail.resep||[]).length">
                      <td colspan="10">
                        <h5 class="text-center">Tidak ada data resep</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Efek Samping Obat E.S.O </h6>
            </div>
            <div class="card-body py-2">
              <div class="row">
                <table class="table table-bordered table-sm">
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Bentuk/manifestasi E.S.O. yang terjadi</h4>
                        <p>{{ dataModal.dataDetail.manifestasi_eso }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Saat/Tanggal mula terjadi</h4>
                        <p>{{ dataModal.dataDetail.tanggal_mula | moment("LL") }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Kesudahan E.S.O</h4>
                        <div class="d-flex mt-1">
                          <i class="icon-checkmark-circle text-success d-inline-block align-middle mr-1"></i>
                          <span class="d-inline-block align-middle">
                            {{ dataModal.dataDetail.kesudahan_eso }}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Riwayat E.S.O yang pernah dialami</h4>
                        <p>{{ dataModal.dataDetail.riwayat_eso }}</p>
                      </div>
                    </td>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Keterangan Tambahan</h4>
                        <p>{{ dataModal.dataDetail.keterangan_tambahan }}</p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Hasil Laboratorium </h6>
            </div>
            <div class="card-body py-2">
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-bordered table-striped table-sm patient-table">
                    <thead>
                      <tr>
                        <th>Hasil Pemeriksaan </th>
                        <th>Hasil</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, k) in dataModal.dataDetail.hasil_lab" :key="`hl-${k}`">
                        <td>{{ v.value }}</td>
                        <td>
                          <div class="result_tab form-group mb-0">
                            <a :href="$parent.uploader(v.file)" class="btn_view" v-b-tooltip.hover title="Unduh Dokumen Hasil" v-if="v.file" target="_blank">
                              <i class="icon-file-download"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="!(dataModal.dataDetail.hasil_lab||[]).length">
                        <td colspan="3">
                          <h5>Hasil Laboratorium tidak ada</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="result_tab mt-2">
                    <h4>Hasil Laboratorium</h4>
                    <p>{{ dataModal.dataDetail.data_laboratorium }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        <div class="col-md-12">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Algoritma Naranjo</h6>
            </div>

            <div class="card-body py-2">
              <table class="table table-bordered">
                <tr>
                  <td colspan="3">
                    <table class="table table-bordered">
                      <tr>
                        <td colspan="3"><strong>PENGIRIM</strong></td>
                      </tr>
                      <tr>
                        <td>Nama </td>
                        <td>:</td>
                        <td>{{ dataModal.dataDetail.p_nama ? dataModal.dataDetail.p_nama : '-' }}</td>
                      </tr>
                      <tr>
                        <td>Keahlian </td>
                        <td>:</td>
                        <td>{{ dataModal.dataDetail.p_keahlian ? dataModal.dataDetail.p_keahlian : '-' }}</td>
                      </tr>
                      <tr>
                        <td>Alamat </td>
                        <td>:</td>
                        <td>{{ dataModal.dataDetail.p_alamat ? dataModal.dataDetail.p_alamat : '-' }}</td>
                      </tr>
                      <tr>
                        <td>Nomor Telepon</td>
                        <td>:</td>
                        <td>{{ dataModal.dataDetail.p_phone ? dataModal.dataDetail.p_phone : '-' }}</td>
                      </tr>
                    </table>

                    <p class="mt-3"><strong>PENJELASAN</strong> </p>
                    <ol>
                      <li style="padding-bottom:10px;">Monitoring Efek Samping Obat (MESO) yang dilakukan di Indonesia bekerja
                        sama dengan WHO- Uppsala Monitoring Center (Collaborating Center for International Drug Monitoring)
                        yang dimaksudkan untuk memonitor semua efek samping obat yang dijumpai pada penggunaan obat.</li>
                      <li style="padding-bottom:10px;">Hasil evaluasi dari semua informasi yang terkumpul akan digunakan
                        sebagai bahan untuk melakukan penilaian kembali obat yang beredar serta untuk melakukan tindakan
                        pengamanan atau penyesuaian yang diperlukan.</li>
                      <li style="padding-bottom:10px;">Umpan balik akan dikirim kepada pelapor.</li>
                    </ol>

                    <p class="mt-3"><strong>ALGORITMA NARANJO</strong> </p>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <td rowspan="2">No</td>
                          <td rowspan="2">Pertanyaan/Questions</td>
                          <td colspan="3">Scale</td>
                        </tr>
                        <tr>
                          <td>Ya/Yes</td>
                          <td>Tidak/No</td>
                          <td>Tidak Diketahui/
                            Unknown
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v, k) in dataModal.dataDetail.naranjo_data" :key="`nara-${k}`">
                          <td>{{ k + 11 }}</td>
                          <td style="vertical-align: top;padding: 2px 8px; text-align: left;">
                            {{ v.label }}
                          </td>
                          <td v-for="(dt, kdt) in v.options" :key="`it-nara-${kdt}`">
                            <span v-if="dt.value == v.score">[v]</span>
                          </td>
                        </tr>

                        <tr>
                          <td></td>
                          <td>TOTAL SCORE</td>
                          <td colspan="3">
                            {{ dataModal.dataDetail.naranjo_score }}
                          </td>
                        </tr>
                      </tbody>
                      
                    </table>
                    <table class="table table-bordered">
                      <tr>
                        <td><strong>SCALE: Score</strong>  </td>
                        <td></td>
                        <td><strong>Category</strong></td>
                      </tr>
                      <tr>
                        <td>9+</td>
                        <td>:</td>
                        <td>Highly probable</td>
                      </tr>
                      <tr>
                        <td>5 - 8</td>
                        <td>:</td>
                        <td>Probable</td>
                      </tr>
                      <tr>
                        <td>1 - 4 </td>
                        <td>:</td>
                        <td>Possible</td>
                      </tr>
                      <tr>
                        <td>0- </td>
                        <td>:</td>
                        <td>Doubtful</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>

  <div v-else>
    <Form />
  </div>

</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
import Formatter from '@/libs/Formatter.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const moment = require('moment')

// import Datepicker from 'vuejs-datepicker'

const _ = global._
import $ from 'jquery'
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  components: {
    DateRangePicker,
    Form
  },
  computed: {
    isDetail() {
      return this.$route.query.isDetail
    },
    isDoc() {
      return this.$route.query.isDoc
    },
    passToSub() {
      return {
        Config: this.Config,
        mrValidation: this.mrValidation,
        rowPasien: this.rowPasien,
        isShowBangsal: this.isShowBangsal,
        infoBangsal: this.infoBangsal,
        katalogBangsal: this.katalogBangsal
      }
    },

    objResepOptions(){
      return this.Config.mr.configResepRanap.reduce((acc, obj) => {
        acc[obj.value] = obj.text
        return acc
      }, {})
    }
  },
  data() {
    return {
      idKey: 'aranres_id',
      datePickerConfig: {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
        autoApply: true,
        ranges: {
          'Hari Ini': [new Date(), new Date()],
          '7 Hari Terakhir': [new Date(moment().subtract(7, 'days')), new Date()],
          '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
          applyLabel: 'Terapkan',
          cancelLabel: 'Batal',
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
        }
      },
      dateRange: {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
      },

      rowEdit: {},
      rowPasien: {},
      rowAdditional: {},
      mRanapBangsal: [],
      mPPJP: [],
      mrValidation: {},
      mrHubunganKeluarga: [],
      mrJenisPertanyaan: [],
      mrPenyampaianJawaban: [],
      mrMetode: [],
      mrMetodeJawaban: [],

      mRoSuku: [],
      mPekerjaan: [],

      dataModal: {
        dataPelayanan: {},
        dataDetail: {},
        dataCreatedDate: [],
        dataPreview: [],
      },
      returObat: [{}],
      obatLen: 0,
      currentPage: 1,
      resepDokter: [],
      mrKesudahanPenyakitUtama: [],
      mrKesudahanESO: [],
      mrPenyakitMenyertai: [],
      lodash: _,

      mObatPreferensi: [],
      mDosis: [],
      mAlatKesehatan: [],
      mAlatInfus: [],
      mrDokter: [],
      mRacikan: [],

      Formatter
    }
  },
  mounted() {
    if (this.isList) {
      this.filter.status_jaminan = 'ALL'
      this.filter.cara_masuk = 'ALL'
      this.filter.ranap_bangsal = this.user.bu_bangsal_id

      this.doConvertDate()
      this.apiGet()

      if (this.isList) {
        this.apiGetResume()
      }

      setTimeout(() => {
        this.setSlide()
      }, 1000)
    } else {
      this.getForm()
    }
  },
  methods: {
    doSubmitMESO(data) {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$router.push({
              name: this.modulePage
            })
          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },
    openDownloadMESO(data) {
      Gen.apiRest(
          "/get/" + this.modulePage, {
            params: {
              type: "get-preview-download",
              id_registrasi: data.aranr_id,
            }
          },
        )
        .then(res => {
          this.$bvModal.show('downloadDoc')
          this.$set(this.dataModal, 'dataPreview', res.data.dataPreview)
        })
        .catch(() => {
          this.$swal.close()
          // this.globalVar.apiGenError( err.response.data )
        })
    },
    downloadMESO(data) {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
          "/do/" + this.modulePage, {
            data: {
              ...data,
              type: "download-meso",
              id_registrasi: data.aranr_id,
            }
          },
          "POST"
        )
        .then(res => {
          const linkSource = res.data
          const downloadLink = document.createElement("a")
          downloadLink.href = linkSource
          downloadLink.download = `Efek-Samping-Obat-${new Date().getTime()}.pdf`
          downloadLink.click()

          this.$swal.close()
        })
        .catch(() => {
          this.$swal.close()
          // this.globalVar.apiGenError( err.response.data )
        })
    },
    openDetail(data) {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: 'get-detail',
            id_registrasi: data.aranr_id,
            id: data.id || null,
          }
        },
      ).then(res => {
        const {
          dataDetail,
          dataCreatedDate
        } = res.data

        this.$set(this, 'dataModal', {
          ...data,
          dataDetail,
          dataCreatedDate,
          selectedDetail: this.dataModal.selectedDetail || dataCreatedDate[0].value
        })
        this.$bvModal.show('detail')
      })
    },

    getForm() {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: 'get-form',
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },
    toMonitoring(regID) {
      this.$router.push({
        name: 'RanapMonitoring',
        params: {
          pageSlug: regID
        }
      }).catch(() => {})
    },
    setSlide() {
      const responsiveTable = document.querySelector('.table-responsive')
      if (responsiveTable) {
        const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
        const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
        if (responsiveTableScroll) {
          // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
          Array.from(responsiveTableScrollActions).forEach(action => {
            action.addEventListener('click', e => {
              const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100

              responsiveTable.scroll({
                left: scrollAmount,
                behavior: 'smooth'
              })
            })
          })
        }
      }
    },
    doResetData() {
      this.doReset()
      this.dateRange = {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
      }
      this.doConvertDate()
    },
    doRefreshData() {
      this.apiGet()
      window.scrollTo(0, 0)
    },
    apiGetResume() {
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      Gen.apiRest(
        "/get/" + this.modulePage + '/resume', {
          params: Object.assign({}, paramsQuery || {})
        }
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },
    doConvertDate() {
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doPageOne() {
      this.filter.page = 1
    },
    doFill() {
      this.doPageOne()
      this.doFilter()
    },
    updateValues(e) {
      this.doConvertDate()
      this.doFill()
    },
    doSearch: _.debounce(function () {
      this.doPageOne()
      this.doFilter()
    }, 500),
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },
    to24Hours(date) {
      return moment(date).add(24, 'hours').format('YYYY-MM-DD')
    },
    validationTime(regDate) {
      if (moment().format('YYYY-MM-DD') >= this.to24Hours(regDate)) {
        return true
      } else {
        return false
      }
    },

    filterObat: _.debounce(function (e, k) {
      if (e) {
        this.returObat[k]['search'] = e
        let id = []
        for (let i = 0; i < (this.returObat || []).length; i++) {
          if (this.returObat[i]['item_id'] && this.returObat[i]['jenis'] == 1) {
            id.push(this.returObat[i]['item_id'])
          }
        }
        Gen.apiRest('/do/' + this.modulePage, {
          data: {
            type: 'select-obat',
            search: e,
            id: id
          }
        }, 'POST').then(res => {
          this.mObatPreferensi = res.data.data
          this.returObat[k]['currentPage'] = 1
          this.returObat[k]['totalRows'] = res.data.totalRows
        })
      }
    }, 10),
  },
  filters: {
    date(val) {
      return val ? moment(val).format("D MMM YYYY") : ""
    }
  },
  watch: {
    $route() {
      if (this.isList) {
        this.apiGet()
        this.apiGetResume()

        setTimeout(() => {
          if (this.isList) {
            this.setSlide()
          }
        }, 1000)
      } else {
        this.getForm()
      }
    },
    '$route.params.pageSlug': function () {
      this.$set(this, 'filter', {})
    },
    'openPerawatPelaksana': function (v) {
      if (!v) {
        this.apiGet()
      }
    }
  }
}
</script>
